import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Container } from "react-bootstrap";
import NavDropdownMain from "../../sub-components/navbar/NavDropdownMain";
import NavbarDefaultRoutes from "../../data/navbars/navbarRoutes";
import qeLogo from "../../images/logos/hfToken.png";
import Socials from "../../sub-components/socials/Socials";
const NavbarDefault = () => {
  const [expandedMenu, setExpandedMenu] = useState(false);

  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="md"
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "1000" /* Ensure the navbar is above other content */,
        }}
        className={`w-100 navbar p-2 ps-1 navbar-default py-0 bg-trans`}>
        <Container fluid className="px-0">
          <Navbar.Brand className="py-0 me-3" as={Link} to="/">
            <div className="d-flex align-items-center h-100">
              <Image
                fluid
                alt="QE Coin Logo"
                style={{ height: "40px" }}
                className="my-2 ms-2 me-5"
                src={qeLogo}
              />
            </div>
          </Navbar.Brand>
          <div className="me-2 me-lg-3">
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="icon-bar top-bar mt-0"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-2 mx-md-auto">
              {NavbarDefaultRoutes.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link
                      className="nladj"
                      key={index}
                      as={Link}
                      to={item.link}
                      onClick={() => {
                        setExpandedMenu(false);
                      }}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return (
                    <NavDropdownMain
                      href=""
                      item={item}
                      key={index}
                      onClick={(value) => setExpandedMenu(value)}
                    />
                  );
                }
              })}
            </Nav>
            <Nav className="ms-md-3 ms-lg-8">
              <div className="mb-3 mb-md-0">
                <Socials si="sm" gap={3} />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default NavbarDefault;
