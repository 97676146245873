import { useEffect } from "react";
import Divider from "../widgets/general/Divider";
import Roadmap from "../sub-components/about/Roadmap";
import Intro from "../sub-components/about/Intro";
import Partners from "../sub-components/about/Partners";

const About = () => {
  useEffect(() => {
    document.title = "HyperFuture Token - The AI Gaming Platform Token";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Intro />
      <Divider />
      <Roadmap />
      <Divider />
      <Partners />
    </div>
  );
};

export default About;
