import { v4 as uuid } from "uuid";

const NavbarDefaultRoutes = [
  {
    id: uuid(),
    menuitem: "About",
    link: "/about",
  },
  {
    id: uuid(),
    menuitem: "Games",
    link: "/games",
  },
  {
    id: uuid(),
    menuitem: "Tokenomics",
    link: "/tokenomics",
  },
  {
    id: uuid(),
    menuitem: "Innovation",
    link: "/innovation",
  },
  {
    id: uuid(),
    menuitem: "Partners",
    link: "/partners",
  },
  // {
  //   id: uuid(),
  //   menuitem: "Merch",
  //   link: "/merch",
  // },
];

export default NavbarDefaultRoutes;
