import { Fragment, useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Tokenomics from "./TokenomicsInfo";
import hfBGMain from "../../images/backgrounds/hfBGMain.mp4";

const QeIntro = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={hfBGMain} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              backgroundColor: "#0000003d",
              position: "relative",
              zIndex: 500,
            }}>
            <div
              className="mx-3 pb-20 pt-5 pt-sm-0 py-20"
              style={{ position: "relative" }}>
              <div
                style={{
                  position: "relative",
                  minHeight: "100vh",
                  alignContent: "center",
                  height: "100%",
                }}>
                <Row className="mt-20 mt-sm-10 mt-md-0">
                  <p className="text-white display-2 text-center">
                    Join the AI Gaming Revolution
                  </p>
                </Row>
                <Row>
                  <div style={{ maxWidth: "1000px" }} className="mx-auto">
                    <p className="text-white fs-3">
                      Meet the intersection of AI, hyper-casual games, and
                      blockchain technology. At HyperFuture, we bring utility
                      and use case for the everyday gamer to receive rewards and
                      digital assets.
                    </p>
                    <p className="text-white fs-3">
                      WE see AI and gaming bringing more of the world's
                      population to blockchain.
                    </p>
                    <p className="text-white fs-3">
                      HyperFuture is the future NOW.
                    </p>
                  </div>
                </Row>
              </div>
              <div className="mt-20 mt-sm-10 mt-md-0">
                <Tokenomics />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default QeIntro;
