import { Fragment, useEffect, useState } from "react";
import { Image, Row } from "react-bootstrap";
import fundingChain from "../../images/partners/fundingChain.png";
import metazooie from "../../images/partners/metazooie.png";
import qe from "../../images/partners/QeLogoWhite.png";
import mediator from "../../images/partners/mediator-white.png";
import hf from "../../images/partners/hfFull-white.png";
import rv from "../../images/partners/rv.png";
import qebg from "../../images/backgrounds/hfBGPartners.mp4";
import Divider from "../../widgets/general/Divider";
const Partners = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    document.title = "QE Coin - Partners";
    window.scrollTo(0, 0);
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                zIndex: -1,
              }}>
              <source src={qebg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: 500,
              backgroundColor: "#0000003d",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}
            className="py-15">
            <div
              style={{
                position: "relative",
                zIndex: "1",
                maxWidth: "1500px",
                alignContent: "center",
                height: "100%",
              }}
              className="mx-auto py-15">
              <h2 className="display-2 text-white text-center mb-10">
                Partners
              </h2>
              <Row className="d-flex justify-content-center gap-5">
                <div
                  style={{
                    width: "300px",
                    alignContent: "center",
                    height: "100%",
                  }}
                  className="mb-5">
                  <div
                    style={{ width: "135px", alignContent: "center" }}
                    className="d-flex justify-content-center mx-auto">
                    <a
                      href="https://www.qechannel.com/"
                      target="_blank"
                      rel="noreferrer noopener">
                      <Image fluid src={qe} />
                    </a>
                  </div>
                </div>
                <div
                  style={{ width: "300px", alignContent: "center" }}
                  className="mb-5">
                  <a
                    href="https://fundingchain.io"
                    target="_blank"
                    rel="noreferrer noopener">
                    <Image fluid src={fundingChain} />
                  </a>
                </div>
                <div
                  style={{ width: "300px", alignContent: "center" }}
                  className="mb-5">
                  <div
                    style={{ width: "155px", alignContent: "center" }}
                    className="d-flex justify-content-center mx-auto">
                    <a
                      href="https://hyperfuture.app/"
                      target="_blank"
                      rel="noreferrer noopener">
                      <Image fluid src={hf} />
                    </a>
                  </div>
                </div>

                <div
                  style={{ width: "300px", alignContent: "center" }}
                  className="mb-5">
                  <a
                    href="https://metazooie.com/"
                    target="_blank"
                    rel="noreferrer noopener">
                    <div
                      style={{ width: "150px", alignContent: "center" }}
                      className="mx-auto">
                      <Image fluid src={metazooie} className="mb-1" />
                    </div>
                    <p className="text-white text-center display-5 mb-0">
                      METAZOOIE
                    </p>
                  </a>
                </div>
                <div
                  style={{ width: "300px", alignContent: "center" }}
                  className="mb-5">
                  <a
                    href="https://fundingchain.io"
                    target="_blank"
                    rel="noreferrer noopener">
                    <Image fluid src={mediator} />
                  </a>
                </div>
                <div
                  style={{ width: "300px", alignContent: "center" }}
                  className="mb-5">
                  <a
                    href="https://rocketverse369.com"
                    target="_blank"
                    rel="noreferrer noopener">
                    <div
                      style={{ width: "160px", alignContent: "center" }}
                      className="mx-auto">
                      <Image fluid src={rv} />
                    </div>
                  </a>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </Fragment>
  );
};
export default Partners;
