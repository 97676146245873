import { Fragment } from "react";
import { Button, Card, Row, Image } from "react-bootstrap";
import gift from "../../images/icons/gift.svg";
import gamepad from "../../images/icons/gamepad.svg";
import build from "../../images/icons/build.svg";
import { useNavigate } from "react-router-dom";
import nash from "../../images/signatures/nash.png";

const FocusPoints = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row className="d-flex justify-content-center gap-5 px-3 mb-5">
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary">
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}>
            <Image height={50} src={gift} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">
            Rewards
          </h3>
          <p className="text-white px-3">
            {`Gaming should be fun and rewarding! Play your favorite games, complete challenges, and level up to earn exclusive HyperFuture tokens. The more you play, the more you earn. It’s that simple!`}
          </p>
        </Card>
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary">
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}>
            <Image height={50} src={gamepad} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">
            In-game Token
          </h3>
          <p className="text-white px-3">
            {`The HyperFuture token lets you customize your gaming experience! Use your tokens to purchase unique skins, power-ups, and special items that enhance your gameplay and make your character stand out.`}
          </p>
        </Card>{" "}
        <Card
          style={{
            width: "400px",
            minWidth: "300px",
            border: "1px solid",
          }}
          className="bg-trans border-secondary">
          <div
            style={{
              position: "absolute",
              marginLeft: "20px",
              marginTop: "20px",
            }}>
            <Image height={50} src={build} />
          </div>
          <h3 className="text-white text-center pt-5 pb-2 display-6">
            Game Development
          </h3>
          <p className="text-white px-3">
            You have a say in how the platform evolves as we build together.
            Your feedback and contributions help us continue building advanced
            AI games and new features that you’ll love.
          </p>
        </Card>
      </Row>
      <div className="d-flex justify-content-center">
        <Button
          style={{ width: "500px" }}
          onClick={() => navigate("/about")}
          className="btn btn-outline-white-trans fs-3 fw-bold mx-10 mb-10 mt-5 shadow-lg">
          <p className="mb-0 fs-3 fw-bold" style={{ zIndex: 500 }}>
            Learn More
          </p>
        </Button>
      </div>
      <div style={{ maxWidth: "800px" }} className="mx-auto mt-10">
        <div className="text-white fs-3 d-flex mx-3 roboto-light">
          "
          <p className="text-uppercase">
            Perhaps it's good to have a beautiful mind, but an even greater gift is to discover a beautiful heart."
          </p>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <Image fluid className="text-white fs-3" src={nash} width={400} />
        </div>
      </div>
    </Fragment>
  );
};
export default FocusPoints;
