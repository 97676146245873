import { Fragment, useEffect } from "react";
import NavbarDefault from "./navbars/NavbarDefault";
import Footer from "./footers/Footer";
import logo from "../images/backgrounds/hfIntro.gif";
import { Image } from "react-bootstrap";

const DefaultLayout = (props) => {
  useEffect(() => {
    document.body.className = "bg-black";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div className="bg-cover-repeat">
        <NavbarDefault />
        <div
          style={{ minHeight: "110vh" }}
          class="logo-container d-flex justify-content-center mt-n5 pb-10">
          <Image fluid src={logo} alt="Logo" className="logo my-auto" />
        </div>
        <main>{props.children}</main>
        <Footer />
      </div>
    </Fragment>
  );
};

export default DefaultLayout;
