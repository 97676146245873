import { Fragment, useState } from "react";
import { Card, Image, Row } from "react-bootstrap";
import miyamoto from "../../images/signatures/miyamoto_signature.png";

const TokenomicsInfo = () => {
  const [minted, setMinted] = useState("0");
  return (
    <Fragment>
      <div className="px-2">
        <h2 className="display-3 text-white text-center mb-5">Tokenomics</h2>
        <Row className="d-flex justify-content-center gap-5 mb-5">
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              100 Billion Max Supply
            </h3>
          </Card>
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              1% Burn Rate
            </h3>
          </Card>
          <Card
            style={{
              width: "400px",
              minWidth: "300px",
              border: "1px solid",
            }}
            className="bg-trans border-secondary">
            <h3 className="text-white text-center pt-4 pb-3 display-6">
              {minted} Minted HyperFuture Tokens
            </h3>
          </Card>
        </Row>
        <Row className="text-white fs-3">
          <div style={{ maxWidth: "1000px" }} className="mx-auto">
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              There will only be 100 billion HyperFuture tokens in existence.
            </p>
            <p>
              HyperFuture Token is a rewards in-game and development token for
              the future of AI gaming. WE see the potential of bringing gamers
              into the blockchain universe.
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              YOU are not a slave to the system.
            </p>
            <p>
              The world is changing every day. It is time for gamers, creators,
              and developers to unite and build a better infrastructure for all.
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="text-white fw-bold text-center">
              HyperFuture wants to lead this revolution!
            </p>
          </div>
        </Row>
        <div style={{ maxWidth: "550px" }} className="mx-auto mt-20">
          <div className="text-white fs-3 d-flex mx-3 text-uppercase roboto-light ">
            "<p>A great idea solves multiple problems at the same time."</p>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <Image
              fluid
              className="text-white fs-3"
              src={miyamoto}
              width={325}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TokenomicsInfo;
