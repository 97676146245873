import { Fragment, useEffect } from "react";
import Roadmap from "../sub-components/about/Roadmap";
import Divider from "../widgets/general/Divider";
import Partners from "../sub-components/about/Partners";

const Innovation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "HyperFuture Token - Innovation Ininerary";
  }, []);

  return (
    <Fragment>
      <Roadmap />
      <Divider />
      <Partners />
    </Fragment>
  );
};
export default Innovation;
