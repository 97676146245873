import { Fragment } from "react";
import { Image } from "react-bootstrap";
import hf from "../../images/logos/hyperfuture.png";
const Hero = () => {
  return (
    <Fragment>
      <div className="mb-10 mx-2 pt-10">
        <div className="d-flex justify-content-center">
          <Image
            fluid
            width={600}
            className=""
            src={hf}
            alt="hYperFuture Logo"
          />
        </div>
        <h1 className="text-white display-3 text-center mb-3">
          The AI Gaming Platform Token
        </h1>
      </div>
    </Fragment>
  );
};

export default Hero;
