import { Fragment, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import q from "../../images/logos/Q.png";
import hfBGRoadmap from "../../images/backgrounds/hfBGRoadmap.mp4";

const Roadmap = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "fit-content",
          overflow: "hidden",
          minHeight: "100vh",
          alignContent: "center",
        }}
        className="bg-black">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            overflow: "hidden",
            pointerEvents: "none",
            zIndex: "0",
          }}>
          <video
            autoPlay={!ios}
            muted
            loop
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
              zIndex: -1,
            }}>
            <source src={hfBGRoadmap} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div
          className="py-15"
          style={{
            position: "relative",
            zIndex: 500,
            backgroundColor: "#00000075",
          }}>
          <div
            style={{ maxWidth: "1000px" }}
            className="mx-0 mb-5 mx-auto py-15">
            <h2 className="text-white display-2 text-center mb-10">
              Innovation Itinerary
            </h2>
            <div className="mx-auto">
              {/* Timeframe Div */}
              <div className="d-flex">
                <div style={{ width: "35%" }} className="mx-2">
                  <div>
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "100%",
                        zIndex: "500",
                        position: "relative",
                      }}
                      className="mx-auto bg-primary shadow-lg text-white fs-3">
                      <p
                        style={{ alignContent: "center" }}
                        className="h-100 text-center mb-0 fw-bold">
                        <Image height={25} src={q} />4 2024
                      </p>
                    </div>
                    <div
                      style={{
                        height: "110px",
                        width: "110px",
                        marginTop: "-105px",
                        borderRadius: "100%",
                        alignContent: "center",
                        zIndex: "400",
                        position: "relative",
                      }}
                      className="mx-auto shadow-lg bg-white"></div>
                  </div>
                  <div
                    style={{
                      width: "5px",
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto bg-white my-n1"></div>
                  <div>
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "100%",
                        zIndex: "500",
                        position: "relative",
                      }}
                      className="mx-auto bg-primary shadow-lg text-white fs-3">
                      <p
                        style={{ alignContent: "center" }}
                        className="h-100 text-center mb-0 fw-bold">
                        <Image height={25} src={q} />1 2025
                      </p>
                    </div>
                    <div
                      style={{
                        height: "110px",
                        width: "110px",
                        marginTop: "-105px",
                        borderRadius: "100%",
                        alignContent: "center",
                        zIndex: "400",
                        position: "relative",
                      }}
                      className="mx-auto shadow-lg bg-white"></div>
                  </div>
                  <div
                    style={{
                      width: "5px",
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto bg-white my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "100%",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto bg-primary shadow-lg text-white fs-3">
                    <p
                      style={{ alignContent: "center" }}
                      className="h-100 text-center mb-0 fw-bold">
                      <Image height={25} src={q} />2 2025
                    </p>
                  </div>
                  <div
                    style={{
                      height: "110px",
                      width: "110px",
                      marginTop: "-105px",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "400",
                      position: "relative",
                    }}
                    className="mx-auto shadow-lg bg-white"></div>
                  <div
                    style={{
                      width: "5px",
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto bg-white my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "100%",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto bg-primary shadow-lg text-white fs-3">
                    <p
                      style={{ alignContent: "center" }}
                      className="h-100 text-center mb-0 fw-bold">
                      <Image height={25} src={q} />3 2025
                    </p>
                  </div>
                  <div
                    style={{
                      height: "110px",
                      width: "110px",
                      marginTop: "-105px",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "400",
                      position: "relative",
                    }}
                    className="mx-auto shadow-lg bg-white"></div>
                  <div
                    style={{
                      width: "5px",
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto bg-white my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "100%",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto bg-primary shadow-lg text-white fs-3">
                    <p
                      style={{ alignContent: "center" }}
                      className="h-100 text-center mb-0 fw-bold">
                      <Image height={25} src={q} />4 2025
                    </p>
                  </div>{" "}
                  <div
                    style={{
                      height: "110px",
                      width: "110px",
                      marginTop: "-105px",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "400",
                      position: "relative",
                    }}
                    className="mx-auto shadow-lg bg-white"></div>
                </div>

                {/* Discription Div */}
                <div style={{ width: "65%" }}>
                  <div className="mx-auto">
                    <div
                      style={{
                        height: "100px",
                        maxWidth: "100%",
                        alignContent: "center",
                        zIndex: "500",
                        position: "relative",
                      }}
                      className="text-white fs-3 text-start">
                      <p className="mb-0">
                        HyperFuture presale and token launch.
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "160px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      maxWidth: "100%",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto text-white fs-3">
                    <p className="mb-0">
                      HyperFuture platform beta release with AI games powered by
                      Unreal Engine, including Alain Robert: The Ultimate Ascent
                      by Metazooie Studios.
                    </p>
                  </div>
                  <div
                    style={{
                      height: "160px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      maxWidth: "100%",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto text-white fs-3">
                    <p className="mb-0">
                      Full release of HyperFuture platform with a variety of
                      premium games and partnerships.
                    </p>
                  </div>
                  <div
                    style={{
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      maxWidth: "100%",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto text-white fs-3">
                    <p className="mb-0">
                      HyperFuture team creates initiative to fund community
                      games to showcase on platform.
                    </p>
                  </div>

                  <div
                    style={{
                      height: "150px",
                      position: "relative",
                      zIndex: "100",
                    }}
                    className="mx-auto my-n1"></div>
                  <div
                    style={{
                      height: "100px",
                      maxWidth: "100%",
                      borderRadius: "100%",
                      alignContent: "center",
                      zIndex: "500",
                      position: "relative",
                    }}
                    className="mx-auto text-white fs-3">
                    <p className="mb-0">
                      Integration of games using AR, VR, and AI into HyperFuture
                      platform and ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Roadmap;
