import { Image, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Divider from "../widgets/general/Divider";
import Partners from "../sub-components/about/Partners";
import hfBGAlain from "../images/backgrounds/hfBGAlain.mp4";
import hfBGAlainM from "../images/backgrounds/hfBGAlainM.mp4";
import hffccover from "../images/games/hffccover.jpg";

const Airdrop = () => {
  const [ios, setIos] = useState(false);

  useEffect(() => {
    document.title = "HyperFuture Token - Games";
    window.scrollTo(0, 0);
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <div>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: "0",
            }}>
            {window.innerWidth < 919 && (
              <video
                autoPlay={!ios}
                muted
                loop
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  minWidth: "100%",
                  minHeight: "100%",
                  objectFit: "cover",
                  pointerEvents: "none",
                  zIndex: -1,
                }}>
                <source
                  src={window.innerWidth < 919 ? hfBGAlainM : hfBGAlain}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
            {window.innerWidth >= 919 && (
              <video
                autoPlay={!ios}
                muted
                loop
                style={{
                  objectFit: "cover",
                  pointerEvents: "none",
                  zIndex: -1,
                }}>
                <source
                  src={window.innerWidth < 919 ? hfBGAlainM : hfBGAlain}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
            }}>
            <div
              className="px-3 py-6 py-20"
              style={{ backgroundColor: "#00000060", minHeight: "100vh" }}>
              <h1 className="text-white text-center display-2 pb-10">
                Alain Robert: The Ultimate Ascent
              </h1>
              <Row
                className="d-flex justify-content-center gap-5 mx-auto"
                style={{
                  maxWidth: "1300px",
                  height: "100%",
                  alignItems: "center",
                }}>
                <p
                  className="text-white fs-3 w-100"
                  style={{
                    maxWidth: "650px",
                    height: "100%",
                    alignItems: "center",
                  }}>
                  <strong>Alain Robert: The Ultimate Ascent</strong> is an
                  adrenaline-filled hyper-casual mobile game where players
                  embody the legendary free solo climber Alain Robert. The goal
                  is to climb to the top of towering buildings and monuments
                  while avoiding and overcoming a variety of obstacles. Players
                  must navigate the challenges with skill, timing, and strategy,
                  all while enjoying the thrill of a free-solo climb.
                </p>

                <Image
                  height={400}
                  src={hffccover}
                  className="rounded"
                  style={{ maxWidth: 400 }}
                />
              </Row>
            </div>
          </div>
          <Divider />
          <Partners />
        </div>
      </div>
    </div>
  );
};

export default Airdrop;
