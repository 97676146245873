import { Routes, Route } from "react-router-dom";
import Landing from "../pages/Landing";
import Games from "../pages/Games";
import About from "../pages/About";
import Tokenomics from "../pages/Tokenomics";
import Innovation from "../pages/Innovation";
import Partners from "../sub-components/about/Partners";

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/games" element={<Games />} />
        <Route exact path="/tokenomics" element={<Tokenomics />} />
        <Route exact path="/innovation" element={<Innovation />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route path="/*" element={<Landing />} />
      </Routes>
    </div>
  );
}
